import * as React from "react";
import { graphql } from 'gatsby';
import uuid from "react-uuid";
import { componentResolver } from "../helpers/component-resolver";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero/hero";
import ContactForm from "../components/form";


function BasicPage({ data }) {
    const page = data.page;
    const sections = componentResolver(page?.relationships?.field_sections);
    const bannerText = page.field_banner_text ? page.field_banner_text : page.title;
    const bannerImage = page.relationships.field_image ? page.relationships.field_image.localFile.publicURL : ``;

    const seoInfo = {
        title: page.field_seo_title ? page.field_seo_title : page.title,
        description: page.field_seo_description ? page.field_seo_description : ''
    }

    return(
        <Layout>
            <Seo title={ seoInfo.title } description={seoInfo.description} />

            <Hero text={bannerText} image={bannerImage} frontClass={page.path.alias === '/home' ? 'front-page-hero' : 'not-front-hero'} />

            <span id="main-content"></span>

            {/* Show each "drupal paragraph" known as "sections" that has been added to the drupal node "sections" field. */}
            {sections &&
                sections.map((item) => {
                    return <React.Fragment key={uuid()}>{item}</React.Fragment>
             })}

            {/* Show "contact form"" only on contact page */}
            {page.path.alias === '/contact' && 
                <ContactForm formid="0OAnydjD" messagelabel="Message" />
            }

     
        </Layout>    
    )
}

export default BasicPage;

export const PageQuery = graphql`
    query PageBySlug($basicPageId: String!) {
        site {
            siteMetadata {
                title
            }
        }

        page: nodePage(id: { eq: $basicPageId }) {
            created(formatString: "dddd Do, MMMM YYYY")
            title
            id
            path {
                alias
            }
            field_seo_description
            field_seo_title
            field_banner_text
            relationships {
                field_image {
                    localFile {
                        publicURL
                    }
                }

                field_sections {
                    __typename
                    ...ParagraphBanner
                    ...ParagraphTextText
                    ...ParagraphServices
                    ...ParagraphTestimonials
                    ...ParagraphTextWithBackgroundColour
                    ...ParagraphAboutSection
                    ...ParagraphBookFeature
                    ...ParagraphRiskForum
                    ...ParagraphArticles
                    ...ParagraphAwards
                  }
            }
        }
    }
`