import React from "react";
import styled from 'styled-components';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: ""
    }; 
  }

  componentDidMount () {
      loadCaptchaEnginge(8); 
   };

  render(props) {
    const { status } = this.state;

    return (
       <Container> 
        <HtmlForm id="contact-form">
            <label htmlFor="name">Your Name</label>
            <input type="text" id="name" name="name" required/>
            <label htmlFor="company-name">Your Company Name</label>
            <input type="text" id="company-name"  name="company-name" required/>
            <label htmlFor="job-title">Your Job Title</label>
            <input type="text" id="job-title" name="job-title"/>
            <label htmlFor="email">Your Email Address</label>
            <input type="email" id="email" name="email"  required />
            <label htmlFor="subject">Subject</label>
            <input type="text" id="subject" name="subject" required/>
            <label htmlFor="message">{'Your ' + this.props.messagelabel}</label>
            <textarea name="message" id="message" required />
            <input type="hidden" name="_url" value="https://www.theopriskcompany.com/contact/" />
            <input type="hidden" name="_next" value="https://www.theopriskcompany.com/contact-thanks" />
            <input type="text" name="_honey" style={{ display: 'none' }} />
            <label htmlForm="user_captcha_input">Please enter the Captcha value into the field below</label>
            <LoadCanvasTemplate />
            <input id="user_captcha_input" name="user_captcha_input" type="text"></input>

            {status === "SUCCESS" ? <p><br/><strong>Thank you for your message, we will get back to you soon!</strong></p> : <button type="submit"  onClick={e => this.submitForm(e)}>Submit</button>}
            {status === "ERROR" && <p>Ooops! There was an error.</p>}
        </HtmlForm>

        <p className="form-terms">BY FILLING AND SUBMITTING THIS FORM, YOU CONSENT TO US CONTACTING YOU REGARDING OUR PRODUCTS AND SERVICES.</p>

      </Container>
 
    );
  }

  submitForm = (e) => {
    e.preventDefault();
    let user_captcha = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(user_captcha) === true) {
        loadCaptchaEnginge(8); 
        document.getElementById('user_captcha_input').value = "";
 
      fetch("https://formsubmit.co/ajax/elena@theopriskcompany.com", {
        method: "POST",
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
            name: document.getElementById('name').value,
            company_name: document.getElementById('company-name').value,
            job_title: document.getElementById('job-title').value,
            email: document.getElementById('email').value,
            subject: document.getElementById('subject').value,
            message: document.getElementById('message').value,
        }),
      })
      .then(response => {
        document.getElementById('contact-form').reset();
        this.setState({ status: "SUCCESS" });
      })
      .catch(error => {
        this.setState({ status: "ERROR" });
        console.log(error);
      });
    }

    else {
        document.getElementById('user_captcha_input').value = "";
        this.setState({ status: "ERROR" });
    }
  }
}

const Container = styled.div`
    max-width: 650px;
    margin: 0 auto;
    
    @media(max-width: 768px) {
        padding: 0 20px;
    }

    .form-terms {
        max-width: 100%;
        font-size: 18px;
        letter-spacing: 1px;
        color: var(--color-primary);
        text-align: center;
        margin-bottom: 40px;
    }
`

const HtmlForm = styled.form`
  margin: 40px auto;
  display: flex;
  flex-direction: column;

  label {
    text-transform: uppercase;
    color: var(--color-secondary);
  }

  button {
    margin-top: 20px;
    display: flex;
    align-self: center;
    color: var(--color-secondary);
    text-transform: uppercase;
    border: 2px solid;
    padding: 10px 40px;
    font-size: 18px;
    border-radius: 15px;
    letter-spacing: 2px;

    &:hover {
      background: #fff;
      color: #272f32;
      cursor: pointer;
    }

    @media screen and (max-width: 479px) {
      width: 100%;
      text-align: center;
      display: block;
    }
  }

  input[type="text"].gotcha {
    margin: 0;
    border: 0;
    height: 0;
    padding: 0;
  }
  
  input[type="text"],
  input[type="email"] {
    background: none;
    border-width: 2px;
    border-color: var(--color-secondary);
    outline: none;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 5px;

     &:focus {
      outline-color: var(--color-secondary);
    }
  }

  textarea {
    background: none;
    border-color: var(--color-secondary);
    outline: none;
    border-width: 2px;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 5px;
    min-height: 100px;
  }

  #canv {
    border-color: var(--color-secondary);
    border-width: 2px;
    padding: 5px;
  }

  #reload_href {
    cursor: pointer;
    margin: 5px 0;
    display: block;
    color: var(--color-primary) !important;
  }
  
`
    
